import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { logoutAuthUser } from "../../utils/firebase/firebase.utils";

import { UserContext } from "../../contexts/user.context";
import { UserInfoContext } from "../../contexts/user-info.context";
import { ChatInfoContext } from "../../contexts/chat-info.context";

import "./user-info-dropdown.styles.scss";

const UserInfoDropdown = () => {
  const { userDocument } = useContext(UserContext);
  const { isUserInfoOpen, setIsUserInfoOpen } = useContext(UserInfoContext);
  const { dispatch } = useContext(ChatInfoContext);
  const [realName, setRealName] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (userDocument && Object.keys(userDocument).length > 0) {
      setRealName(userDocument.realName);
      setUsername(userDocument.username);
    }
  }, [userDocument]);

  const handleClick = () => {
    setIsUserInfoOpen(false);
  };

  const handleLogout = () => {
    logoutAuthUser();
    setIsUserInfoOpen(false);
    dispatch({ type: "RESET" });
  };

  return (
    <div
      className={`user-info-dropdown-container ${
        isUserInfoOpen ? "open" : "close"
      }`}
    >
      <ul className="user-info-dropdown-list">
        <li className="user-info-dropdown-item user-info">Hi, {realName}</li>
        <Link to={`/profile/${username}`} onClick={handleClick}>
          <li className="user-info-dropdown-item">Profil</li>
        </Link>
        <Link to={`/change-password/${username}`} onClick={handleClick}>
          <li className="user-info-dropdown-item">Ganti Kata Sandi</li>
        </Link>
        <li className="user-info-dropdown-item" onClick={handleLogout}>
          Keluar
        </li>
        <li
          className="user-info-dropdown-item user-info-dropdown-close"
          onClick={handleClick}
        >
          Tutup Menu
        </li>
      </ul>
    </div>
  );
};

export default UserInfoDropdown;
