import { useState, useContext } from "react";
import { v4 as uuid } from "uuid";

import { UserContext } from "../../contexts/user.context";
import { ChatInfoContext } from "../../contexts/chat-info.context";

import {
  updateChatsDocument,
  updateUserChatsDocument,
  uploadFile,
} from "../../utils/firebase/firebase.utils";

import Img from "../../images/img.png";
import "./chat-input.styles.scss";

const ChatInput = () => {
  const [text, setText] = useState("");
  const [image, setImage] = useState(null);

  const { currentUser } = useContext(UserContext);
  const { data } = useContext(ChatInfoContext);

  const handleChangeText = (event) => {
    setText(event.target.value);
  };

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
  };

  const handleSend = async () => {
    if (image) {
      const uUid = uuid();
      const imageUrl = await uploadFile("chat-image", image, uUid);
      await updateChatsDocument(
        data.combinedId,
        uUid,
        currentUser.uid,
        text,
        imageUrl
      );
    } else {
      await updateChatsDocument(data.combinedId, uuid(), currentUser.uid, text);
    }

    await updateUserChatsDocument(currentUser, data.combinedId, true, {
      [data.combinedId + ".lastMessage"]: {
        text,
        status: "read",
      },
    });

    await updateUserChatsDocument(data.userInfo, data.combinedId, true, {
      [data.combinedId + ".lastMessage"]: {
        text,
        status: "unread",
      },
    });

    setText("");
    setImage(null);
  };

  const handleKeyDown = (event) => {
    event.code == "Enter" && handleSend();
  };

  return (
    <div className="chat-input">
      <input
        type="text"
        placeholder="Ketik pesan..."
        onChange={handleChangeText}
        onKeyDown={handleKeyDown}
        value={text}
      />
      <div className="send">
        <input
          type="file"
          style={{ display: "none" }}
          id="file"
          onChange={handleChangeFile}
        />
        <label htmlFor="file">
          <img src={Img} alt="" />
        </label>
        <button onClick={handleSend}>Kirim</button>
      </div>
    </div>
  );
};

export default ChatInput;
