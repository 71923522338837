import ChangePasswordForm from "../../components/change-password-form/change-password-form.component";
import "./change-password.styles.scss";

const ChangePassword = () => {
  return (
    <div>
      <h2 className="change-password-page-title">Masukkan kata sandi baru</h2>
      <ChangePasswordForm />
    </div>
  );
};

export default ChangePassword;
