import { useState, useContext } from "react";

import { UserContext } from "../../contexts/user.context";
import { UsersContext } from "../../contexts/users.context";
import { ChatInfoContext } from "../../contexts/chat-info.context";

import {
  createChatsDocument,
  updateUserChatsDocument,
} from "../../utils/firebase/firebase.utils";
import "./chat-search.styles.scss";

const ChatSearch = () => {
  const [username, setUsername] = useState("");
  const [searchedUsers, setSearchedUsers] = useState([]);
  const { currentUser, userDocument } = useContext(UserContext);
  const { users } = useContext(UsersContext);
  const { dispatch } = useContext(ChatInfoContext);

  const handleChange = (event) => {
    if (event.target.value == "") {
      setSearchedUsers([]);
    }
    setUsername(event.target.value.toLocaleLowerCase());
  };

  const handleSearch = () => {
    const searchedUsers = users.filter((user) =>
      user.username.toLocaleLowerCase().includes(username)
    );

    if (searchedUsers.length < 1) {
      setSearchedUsers([]);
    }

    setSearchedUsers(searchedUsers);
  };

  const handleKeyDown = (event) => {
    event.code == "Enter" && handleSearch();
  };

  const handleSelect = async (searchedUser) => {
    const combinedId =
      userDocument.uid > searchedUser.uid
        ? userDocument.uid + searchedUser.uid
        : searchedUser.uid + userDocument.uid;

    await createChatsDocument(combinedId);

    await updateUserChatsDocument(currentUser, combinedId, true, {
      [combinedId + ".userInfo"]: {
        uid: searchedUser.uid,
        username: searchedUser.username,
        profilePicture: searchedUser.profilePicture,
      },
    });

    await updateUserChatsDocument(searchedUser, combinedId, true, {
      [combinedId + ".userInfo"]: {
        uid: userDocument.uid,
        username: userDocument.username,
        profilePicture: userDocument.profilePicture,
      },
    });

    dispatch({
      type: "CHANGE_USER",
      payload: {
        uid: searchedUser.uid,
        username: searchedUser.username,
        profilePicture: searchedUser.profilePicture,
      },
    });

    setSearchedUsers([]);
    setUsername("");
  };

  return (
    <div className="chat-search">
      <div className="chat-search-form">
        <input
          type="text"
          placeholder="Cari dengan Username"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={username}
        />
      </div>
      {searchedUsers.length > 0 &&
        searchedUsers.map((searchedUser) => {
          return (
            <div
              className="user-chat"
              onClick={() => handleSelect(searchedUser)}
              key={searchedUser.uid}
            >
              <div className="user-chat-content">
                <img src={searchedUser.profilePicture} alt="" />
                <div className="user-chat-info">
                  <span>{searchedUser.username}</span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ChatSearch;
