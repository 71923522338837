import { FaStar } from "react-icons/fa";
import "./rating.styles.scss";

const Rating = ({ ratingValueTotal, ratingCount }) => {
  return (
    <div className="rating-container">
      <FaStar className="rating-icon" />
      <span className="rating-score">
        {ratingCount ? (ratingValueTotal / ratingCount).toFixed(1) : 0}
      </span>
      <span className="rating-number">{`(${ratingCount})`}</span>
    </div>
  );
};

export default Rating;
