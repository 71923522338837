import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  updateUserDocument,
  uploadFile,
} from "../../utils/firebase/firebase.utils";
import { UserContext } from "../../contexts/user.context";
import { UsersContext } from "../../contexts/users.context";
import "./profile.styles.scss";

const schema = yup.object().shape({
  imageUrl: yup
    .mixed()
    .test("fileSize", "Ukuran Foto Profil maksimal 3 MB", (value) => {
      if (value.length == 0) {
        return true;
      } else {
        return value && value[0].size <= 3000000;
      }
    }),
});

const defaultFormFields = {
  email: "",
  username: "",
  realName: "",
  imageUrl: "",
  gender: "default",
  dateOfBirth: "",
  city: "",
  state: "",
  description: "",
  favouriteFood: "",
  hobby: "",
};

const Profile = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const {
    email,
    username,
    realName,
    imageUrl,
    gender,
    dateOfBirth,
    city,
    state,
    description,
    favouriteFood,
    hobby,
  } = formFields;

  const [profilePictureImage, setProfilePictureImage] = useState(null);

  const {
    currentUser,
    userDocument,
    userDocumentUpdateFlag,
    setUserDocumentUpdateFlag,
  } = useContext(UserContext);
  const { usernames } = useContext(UsersContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    setFormFields({ ...userDocument, imageUrl: "" });
  }, [userDocument]);

  const submitHandler = async (data, event) => {
    event.preventDefault();

    if (usernames[username.toLocaleLowerCase()]) {
      alert("Username telah terpakai, silahkan masukkan Username lain");
      return;
    }

    try {
      console.log("try block");

      if (imageUrl) {
        const profilePicture = await uploadFile(
          "profile-picture",
          profilePictureImage,
          currentUser.uid
        );

        // console.log("done getting profile picture url");

        await updateUserDocument(currentUser, {
          email,
          username,
          realName,
          profilePicture,
          gender,
          dateOfBirth,
          city,
          state,
          description,
          favouriteFood,
          hobby,
        });
      } else {
        await updateUserDocument(currentUser, {
          email,
          username,
          realName,
          gender,
          dateOfBirth,
          city,
          state,
          description,
          favouriteFood,
          hobby,
        });
      }

      // console.log("done updating user document");

      setUserDocumentUpdateFlag(!userDocumentUpdateFlag);
    } catch (error) {
      alert("Terjadi kesalahan dalam pembaruan data profil");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleImageUrlChange = (event) => {
    const { value, files } = event.target;
    setFormFields({ ...formFields, imageUrl: value });
    setProfilePictureImage(files[0]);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submitHandler)} className="profile-form">
        <img className="profilePicture" src={userDocument.profilePicture} />
        <div className="group">
          <input
            {...register("imageUrl")}
            className="form-input form-file"
            type="file"
            accept=".jpg, .jpeg"
            onChange={handleImageUrlChange}
            value={imageUrl}
          />
          <label className="form-input-label slide">Foto Profil</label>
          <label
            className={`form-file-label ${imageUrl.length ? "exist" : "empty"}`}
          >
            {imageUrl.length ? imageUrl : "Nama File"}
          </label>
          {errors.imageUrl && <p>{errors.imageUrl.message}</p>}
        </div>
        <div className="group">
          <input
            disabled
            className="form-input"
            type="email"
            name="email"
            value={email}
          />
          <label className="form-input-label slide">Email</label>
        </div>
        <div className="group">
          <input
            required
            className="form-input"
            type="text"
            onChange={handleChange}
            name="username"
            value={username}
            maxLength="20"
          />
          <label className="form-input-label slide">Username</label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="text"
            onChange={handleChange}
            name="realName"
            value={realName}
          />
          <label className="form-input-label slide">Nama Asli</label>
        </div>
        <div className="group">
          <select
            className="form-input"
            onChange={handleChange}
            name="gender"
            value={gender}
          >
            <option value="default"></option>
            <option value="laki-laki">Laki-laki</option>
            <option value="perempuan">Perempuan</option>
          </select>
          <label className="form-input-label slide">Jenis Kelamin</label>
        </div>
        <div className="group">
          <input
            className={`form-input form-date ${
              dateOfBirth.length ? "exist" : "empty"
            }`}
            type="date"
            // type="text"
            // onFocus={(event) => (event.target.type = "date")}
            // onBlur={(event) => (event.target.type = "text")}
            onChange={handleChange}
            name="dateOfBirth"
            value={dateOfBirth}
          />
          <label className="form-input-label slide">Tanggal Lahir</label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="text"
            onChange={handleChange}
            name="city"
            value={city}
          />
          <label className="form-input-label slide">Kota</label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="text"
            onChange={handleChange}
            name="state"
            value={state}
          />
          <label className="form-input-label slide">Provinsi</label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="text"
            onChange={handleChange}
            name="description"
            value={description}
            maxLength="80"
          />
          <label className="form-input-label slide">Deskripsi Diri</label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="text"
            onChange={handleChange}
            name="favouriteFood"
            value={favouriteFood}
            maxLength="20"
          />
          <label className="form-input-label slide">Makanan Favorit</label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="text"
            onChange={handleChange}
            name="hobby"
            value={hobby}
            maxLength="20"
          />
          <label className="form-input-label slide">Hobi</label>
        </div>
        <button className="form-button">Perbarui</button>
      </form>
    </div>
  );
};

export default Profile;
