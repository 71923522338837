import { useContext } from "react";
import { RiArrowGoBackLine } from "react-icons/ri";

import { ChatInfoContext } from "../../contexts/chat-info.context";
import "./back-to-chat-list.styles.scss";

const BackToChatList = () => {
  const { dispatch } = useContext(ChatInfoContext);

  const handleClick = () => {
    dispatch({ type: "RESET" });
  };

  return (
    <div className="back-to-chat-list" onClick={handleClick}>
      <RiArrowGoBackLine className="back-icon" size="1.5em" color="white" />
    </div>
  );
};

export default BackToChatList;
