import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiMail } from "react-icons/fi";

import { UserContext } from "../../contexts/user.context";
import { onUserChatsChangedListener } from "../../utils/firebase/firebase.utils";

import "./chat-icon.styles.scss";

const ChatIcon = () => {
  const [userChats, setUserChats] = useState({});
  const [unreadChatCount, setUnreadChatCount] = useState(0);
  const { currentUser, userDocument } = useContext(UserContext);

  useEffect(() => {
    const unsubscribe = onUserChatsChangedListener(currentUser, (doc) => {
      setUserChats(doc.data());
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!userChats) return;

    setUnreadChatCount(
      Object.entries(userChats).reduce((unreadChatCount, userChat) => {
        return userChat[1].lastMessage?.status == "unread"
          ? unreadChatCount + 1
          : unreadChatCount;
      }, 0)
    );
  }, [userChats]);

  return (
    userDocument &&
    Object.keys(userDocument).length > 0 && (
      <Link
        className="chat-icon-container"
        to={`/chat/${userDocument.username}`}
      >
        <FiMail className="chat-icon" size="1.8em" color="gray" />
        <span
          className={`chat-notification ${unreadChatCount ? "unread" : "read"}`}
        >
          &#8226;
        </span>
      </Link>
    )
  );
};

export default ChatIcon;
