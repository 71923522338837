import { useState, useContext, useEffect, Fragment } from "react";
import { FaStar } from "react-icons/fa";

import { UserContext } from "../../contexts/user.context";
import { ChatInfoContext } from "../../contexts/chat-info.context";
import { UsersRatingContext } from "../../contexts/user-rating.context";
import { updateUserRatingDocument } from "../../utils/firebase/firebase.utils";
import "./rate.styles.scss";

const Rate = () => {
  const [rating, setRating] = useState(null);
  const [ratingHover, setRatingHover] = useState(null);

  const { currentUser } = useContext(UserContext);
  const { data } = useContext(ChatInfoContext);
  const { usersRating, usersRatingUpdateFlag, setUsersRatingUpdateFlag } =
    useContext(UsersRatingContext);

  useEffect(() => {
    const ratingValue = usersRating[data.userInfo.uid]?.ratingValue;
    typeof ratingValue !== "undefined"
      ? setRating(ratingValue)
      : setRating(null);
  }, [data, usersRating]);

  // console.log(data);
  // console.log(rating);

  const handleClick = async (event) => {
    await updateUserRatingDocument(
      currentUser,
      data.userInfo,
      parseInt(event.target.value)
    );

    setUsersRatingUpdateFlag(!usersRatingUpdateFlag);
  };

  if (rating) {
    return (
      <div className="rate-container">
        <span className="rate-message">Penilaian</span>
        <div>
          {[...Array(5)].map((star, i) => {
            const starValue = i + 1;

            return (
              <label key={i}>
                <input type="radio" name="rate" value={starValue} />
                <FaStar
                  className="rate-icon"
                  color={starValue <= rating ? "#fdb813" : "#ffffff"}
                />
              </label>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="rate-container">
      <span className="rate-message">Penilaian</span>
      <div>
        {[...Array(5)].map((star, i) => {
          const starValue = i + 1;

          return (
            <label key={i}>
              <input
                type="radio"
                name="rate"
                value={starValue}
                onClick={(event) => {
                  handleClick(event);
                  setRatingHover(null);
                }}
              />
              <FaStar
                className="rate-icon"
                color={starValue <= ratingHover ? "#fdb813" : "#ffffff"}
                onMouseEnter={() => setRatingHover(starValue)}
                onMouseLeave={() => setRatingHover(null)}
              />
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default Rate;

// rate component
// currentUser
// data chatInfoContext

// userRating
//     currentUserUid
//         userUid{date, ratingValue}

// onClick updateUserRating{date, ratingValue}, updateUserDocument{ratingValueTotal+=ratingValue, ratingCount++}

// chat-content component
// currentUser
// chat-info Context
// useEffect getUserRating
