import ChatContent from "../../components/chat-content/chat-content.component";
import ChatSidebar from "../../components/chat-sidebar/chat-sidebar.component";

import "./chat.styles.scss";

const Chat = () => {
  return (
    <div className="chat">
      <div className="container">
        <ChatSidebar />
        <ChatContent />
      </div>
    </div>
  );
};

export default Chat;
