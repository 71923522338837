import { createContext } from "react";
import { useMediaQuery } from "react-responsive";

export const MediaQueryContext = createContext({
  isMobileLandscapeOrTablet: false,
});

export const MediaQueryProvider = ({ children }) => {
  const isMobileLandscapeOrTablet = useMediaQuery({
    query: "(min-width: 576px)",
  });
  const value = { isMobileLandscapeOrTablet };

  //   console.log(isMobileLandscapeOrTablet);

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
};
