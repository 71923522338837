// import { Component } from 'react';
import { BiSearch } from "react-icons/bi";
import "./search-box.styles.scss";

const SearchBox = ({ placeholder, onSearchEnter, onClearSearch }) => (
  <form className="searchbox-container">
    <BiSearch size="1.1em" color="gray" className="search-icon" />
    <input
      type="search"
      className="searchbox"
      placeholder={placeholder}
      onKeyDown={onSearchEnter}
      onChange={onClearSearch}
    />
  </form>
);

// const SearchBox = ({
//   className,
//   placeholder,
//   onSearchEnter,
//   onClearSearch,
// }) => (
//   <form>
//     <Icon circular name="search" id="search-icon" />
//     <input
//       type="search"
//       className={`search-box ${className}`}
//       placeholder={placeholder}
//       onKeyDown={onSearchEnter}
//       onChange={onClearSearch}
//     />
//   </form>
// );

// class SearchBox extends Component {
//     render() {
//         return (
//             <input
//                 type='search'
//                 className={`search-box ${this.props.className}`}
//                 placeholder={this.props.placeholder}
//                 onKeyDown={this.props.onEnter}
//                 onChange={this.props.onClear}
//             />
//         );
//     }
// }

export default SearchBox;
