// import { Component } from 'react';
import ProfileCard from "../profile-card/profile-card.component";
import "./profile-list.styles.scss";

const ProfileList = ({ users }) => (
  <div className="profile-list">
    {users.map((user) => {
      return <ProfileCard user={user} key={user.uid} />;
    })}
  </div>
);

// class ProfileList extends Component {
//     render() {
//         const { users } = this.props;

//         return (
//             <div className='profile-list'>
//                 {users.map((user) => {
//                     return (
//                         <ProfileCard user={user} />
//                     );
//                 })}
//             </div>
//         );
//     }
// }

export default ProfileList;
