import { useState, useContext, useEffect } from "react";

import { UserContext } from "../../contexts/user.context";
import { ChatInfoContext } from "../../contexts/chat-info.context";
import {
  updateUserChatsDocument,
  onUserChatsChangedListener,
} from "../../utils/firebase/firebase.utils";

import "./chat-list.styles.scss";

const ChatList = () => {
  const [userChats, setUserChats] = useState([]);
  const { currentUser } = useContext(UserContext);
  const { dispatch } = useContext(ChatInfoContext);

  useEffect(() => {
    const unsubscribe = onUserChatsChangedListener(currentUser, (doc) => {
      setUserChats(doc.data());
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const getLastMessageTimestamp = (date) => {
    if (!date) return "";

    const lastMessageDate = date.getDate();
    const lastMessageMonth = date.getMonth() + 1;
    const lastMessageYear = date.getFullYear();
    const lastMessageHours = date.getHours();
    const lastMessageMinutes =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

    const currentTimestamp = new Date();
    const currentDate = currentTimestamp.getDate();
    const currentMonth = currentTimestamp.getMonth() + 1;
    const currentYear = currentTimestamp.getFullYear();

    if (
      lastMessageDate !== currentDate ||
      lastMessageMonth !== currentMonth ||
      lastMessageYear !== currentYear
    ) {
      const lastMessageYearShortend = lastMessageYear.toString().substring(2);
      return `${lastMessageDate}/${lastMessageMonth}/${lastMessageYearShortend}`;
    } else {
      return `${lastMessageHours}:${lastMessageMinutes}`;
    }
  };

  const handleSelect = (combinedId, userInfo) => {
    dispatch({ type: "CHANGE_USER", payload: userInfo });

    updateUserChatsDocument(currentUser, combinedId, false, {
      [combinedId + ".lastMessage.status"]: "read",
    });
  };

  return (
    <div className="chat-list">
      {Object.entries(userChats)
        ?.sort((a, b) => b[1].date - a[1].date)
        .map((userChat) => (
          <div
            className="user-chat"
            key={userChat[0]}
            onClick={() => handleSelect(userChat[0], userChat[1].userInfo)}
          >
            <div className="user-chat-content">
              <img src={userChat[1].userInfo.profilePicture} alt="" />
              <div className="user-chat-info">
                <span>{userChat[1].userInfo.username}</span>
                {userChat[1].lastMessage?.text && (
                  <p>{userChat[1].lastMessage.text}</p>
                )}
              </div>
            </div>
            <div className="user-chat-status">
              {userChat[1].lastMessage?.text && (
                <span className="user-chat-timestamp">
                  {getLastMessageTimestamp(userChat[1].date?.toDate())}
                </span>
              )}
              <span
                className={
                  userChat[1].lastMessage?.status == "unread"
                    ? "unread"
                    : "read"
                }
              >
                &#8226;
              </span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ChatList;
