import { createContext, useState, useEffect, useContext } from "react";

import { UserContext } from "./user.context";
import { onUsersChangedListener } from "../utils/firebase/firebase.utils";

export const UsersContext = createContext({
  users: [],
  allUsernames: {},
  usernames: {},
  allEmails: {},
});

export const UsersProvider = ({ children }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [allUsernames, setAllUsernames] = useState({});
  const [usernames, setUsernames] = useState({});
  const [allEmails, setAllEmails] = useState({});
  const value = {
    users,
    allUsernames,
    usernames,
    allEmails,
  };

  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    const unsubscribe = onUsersChangedListener((usersCollection) => {
      const allUsers = usersCollection.docs.map((doc) => doc.data());
      setAllUsers(allUsers);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const obj = {};

    allUsers.forEach((elem) => {
      obj[elem.username.toLocaleLowerCase()] = 1;
    });

    setAllUsernames(obj);
  }, [allUsers]);

  useEffect(() => {
    const obj = {};

    allUsers.forEach((elem) => {
      obj[elem.email.toLocaleLowerCase()] = 1;
    });

    setAllEmails(obj);
  }, [allUsers]);

  useEffect(() => {
    if (currentUser) {
      const allUsersExceptCurrentUser = allUsers.filter(
        (user) => user.uid !== currentUser.uid
      );
      setUsers(allUsersExceptCurrentUser);
    } else setUsers([]);
  }, [currentUser, allUsers]);

  useEffect(() => {
    if (users.length > 0) {
      const obj = {};

      users.forEach((elem) => {
        obj[elem.username.toLocaleLowerCase()] = 1;
      });

      setUsernames(obj);
    } else setUsernames({});
  }, [users]);

  // console.log(allEmails);
  // console.log(allUsernames);
  // console.log(users);
  // console.log(usernames);

  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};
