import { useContext } from "react";
import { Outlet, Link } from "react-router-dom";
import { Sticky } from "semantic-ui-react";

import Logo from "../../components/logo/logo.component";
import SearchBox from "../../components/search-box/search-box.component";
import ChatIcon from "../../components/chat-icon/chat-icon.component";
import UserInfo from "../../components/user-info/user-info.component";
import UserInfoDropdown from "../../components/user-info-dropdown/user-info-dropdown.component";

import { UserContext } from "../../contexts/user.context";
import { UserInfoContext } from "../../contexts/user-info.context";
import { ParamsContext } from "../../contexts/params.context";

import "./header.styles.scss";

const Header = ({ onSearchEnter, onClearSearch, contextRef }) => {
  const { currentUser } = useContext(UserContext);
  const { isUserInfoOpen } = useContext(UserInfoContext);
  const { params } = useContext(ParamsContext);

  return (
    <div ref={contextRef}>
      <Sticky context={contextRef}>
        <div className="header-container">
          <div className="header-logo-searchbox-container">
            <Logo />
            {currentUser && params && (
              <div className="header-searchbox">
                <SearchBox
                  placeholder="Cari Teman"
                  onSearchEnter={onSearchEnter}
                  onClearSearch={onClearSearch}
                />
              </div>
            )}
          </div>
          <div className="header-links-container">
            {currentUser && <ChatIcon />}
            {currentUser && <UserInfo />}
            {!currentUser && (
              <Link className="header-link" to="/login">
                Masuk/Daftar
              </Link>
            )}
          </div>
          <UserInfoDropdown />
        </div>
      </Sticky>

      <Outlet />
    </div>
  );
};

export default Header;
