import { createContext, useState } from "react";

export const ParamsContext = createContext({
  params: "",
  setParams: () => {},
});

export const ParamsProvider = ({ children }) => {
  const [params, setParams] = useState("");
  const value = { params, setParams };

  return (
    <ParamsContext.Provider value={value}>{children}</ParamsContext.Provider>
  );
};
