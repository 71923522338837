import { Link } from "react-router-dom";
import RegisterForm from "../../components/register-form/register-form.component";
import "./register.styles.scss";

const Register = () => {
  return (
    <div>
      <h2 className="register-page-title">
        Daftar sekarang untuk buat janjian makan
      </h2>
      <RegisterForm />
      <div className="to-login-page">
        Sudah punya akun?{" "}
        <Link className="to-login-page-link" to="/login">
          Masuk
        </Link>
      </div>
    </div>
  );
};

export default Register;
