import { Link } from "react-router-dom";

import LoginForm from "../../components/login-form/login-form.component";
import "./login.styles.scss";

const Login = () => {
  return (
    <div>
      <h2 className="login-page-title">Masuk untuk buat janjian makan</h2>
      <LoginForm />
      <div className="to-register-page">
        Belum punya akun?{" "}
        <Link className="to-register-page-link" to="/register">
          Daftar
        </Link>
      </div>
      <div className="to-forget-password-page">
        Lupa kata sandi?{" "}
        <Link className="to-forget-password-page-link" to="/forget-password">
          Reset kata sandi
        </Link>
      </div>
    </div>
  );
};

export default Login;
