// import { Component } from 'react';
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  createChatsDocument,
  updateUserChatsDocument,
} from "../../utils/firebase/firebase.utils";
import { UserContext } from "../../contexts/user.context";
import { ChatInfoContext } from "../../contexts/chat-info.context";
import Rating from "../rating/rating.component";
import "./profile-card.styles.scss";

const ProfileCard = ({ user }) => {
  const {
    username,
    realName,
    profilePicture,
    description,
    city,
    state,
    favouriteFood,
    hobby,
    uid,
    ratingValueTotal,
    ratingCount,
  } = user;

  const { currentUser, userDocument } = useContext(UserContext);
  const { dispatch } = useContext(ChatInfoContext);

  const navigate = useNavigate();

  const handleClick = async () => {
    const combinedId =
      userDocument.uid > uid ? userDocument.uid + uid : uid + userDocument.uid;

    await createChatsDocument(combinedId);

    await updateUserChatsDocument(currentUser, combinedId, true, {
      [combinedId + ".userInfo"]: {
        uid: uid,
        username: username,
        profilePicture: profilePicture,
      },
    });

    await updateUserChatsDocument(user, combinedId, true, {
      [combinedId + ".userInfo"]: {
        uid: userDocument.uid,
        username: userDocument.username,
        profilePicture: userDocument.profilePicture,
      },
    });

    dispatch({
      type: "CHANGE_USER",
      payload: {
        uid: uid,
        username: username,
        profilePicture: profilePicture,
      },
    });

    navigate(`/chat/${userDocument.username}`);
  };

  return (
    <div className="profile-card-container">
      <img src={profilePicture} alt={`foto ${username}`} />
      <div className="profile-card-content">
        <div className="profile-card-user-info">
          <p className="username">{username}</p>
          <p className="real-name">{realName}</p>
          <Rating
            ratingValueTotal={ratingValueTotal}
            ratingCount={ratingCount}
          />
          <p className="area">
            <span className="city">{city}</span>
            <span className="state">,&nbsp;{state}</span>
          </p>
          <div className="description-container">
            <div className="description">{description}</div>
            <div className="additional-description">
              <p className="favourite-food">Makanan favorit: {favouriteFood}</p>
              <p className="hobby">Hobi: {hobby}</p>
            </div>
          </div>
        </div>
        <button className="ajak-makan-button" onClick={handleClick}>
          Ajak Makan
        </button>
      </div>
    </div>
  );
};

// class ProfileCard extends Component {
//     render() {
//         const { id, name, email } = this.props.user;

//         return(
//             <div className='profile-card-container' key={id}>
//                 <img
//                     src={`https://robohash.org/${id}?set=set4&size=180x180`}
//                     alt={`Username ${name}`}
//                 />
//                 <h2>{name}</h2>
//                 <p>{email}</p>
//             </div>
//         );
//     }
// }

export default ProfileCard;
