import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { UserContext } from "../../contexts/user.context";
import { changePassword } from "../../utils/firebase/firebase.utils";
import "./change-password-form.styles.scss";

const defaultFormFields = {
  newPassword: "",
  confirmNewPassword: "",
};

const ChangePasswordForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { newPassword, confirmNewPassword } = formFields;

  const { userDocument } = useContext(UserContext);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      alert("Kata sandi baru tidak sama");
      return;
    }

    await changePassword(newPassword);

    // console.log("done changing password");

    navigate(`/${userDocument.username}`);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="change-password-form">
        <div className="group">
          <input
            className="form-input"
            type="password"
            required
            onChange={handleChange}
            name="newPassword"
            value={newPassword}
          />
          <label
            className={`form-input-label ${newPassword.length ? "slide" : ""}`}
          >
            Kata Sandi Baru*
          </label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="password"
            required
            onChange={handleChange}
            name="confirmNewPassword"
            value={confirmNewPassword}
          />
          <label
            className={`form-input-label ${
              confirmNewPassword.length ? "slide" : ""
            }`}
          >
            Ulangi Kata Sandi Baru*
          </label>
        </div>
        <button className="form-button">Ubah Kata Sandi</button>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
