import { useContext } from "react";

import { ChatInfoContext } from "../../contexts/chat-info.context";
import BackToChatList from "../back-to-chat-list/back-to-chat-list.component";
import Rate from "../rate/rate.component";
import ChatMessages from "../chat-messages/chat-messages.component";
import ChatInput from "../chat-input/chat-input.component";

import "./chat-content.styles.scss";

const ChatContent = () => {
  const { data } = useContext(ChatInfoContext);

  return (
    <div
      className={`chat-content ${
        data.userInfo.username ? "show-chat-content" : "hide-chat-content"
      }`}
    >
      <div className="chat-content-info">
        <div className="chat-content-info-back-to-chat-list-username">
          <BackToChatList />
          <span className="chat-content-info-username">
            {data.userInfo.username}
          </span>
        </div>
        {data.userInfo.username && <Rate />}
      </div>
      {data.userInfo.username ? (
        <ChatMessages />
      ) : (
        <p className="chat-content-empty-message">
          Cari teman & ngobrol sekarang
        </p>
      )}
      {data.userInfo.username && <ChatInput />}
    </div>
  );
};

export default ChatContent;
