import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { UsersContext } from "../../contexts/users.context";
import { resetPassword } from "../../utils/firebase/firebase.utils";
import "./forget-password-form.styles.scss";

const ForgetPasswordForm = () => {
  const [email, setEmail] = useState("");

  const { allEmails } = useContext(UsersContext);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!allEmails[email]) {
      alert("email tidak terdaftar");
      return;
    }

    await resetPassword(email);

    // console.log("email reset kata sandi telah dikirim");

    navigate(`/login`);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="forget-password-form">
        <div className="group">
          <input
            className="form-input"
            type="email"
            required
            onChange={handleChange}
            name="email"
            value={email}
          />
          <label className={`form-input-label ${email.length ? "slide" : ""}`}>
            Email*
          </label>
        </div>
        <button className="form-button">Kirim Email Reset Kata Sandi</button>
      </form>
    </div>
  );
};

export default ForgetPasswordForm;
