import "./chat-message-date.styles.scss";

const ChatMessageDate = ({ messageTimestamp }) => {
  const getMonthString = (month) => {
    switch (month) {
      case 0:
        return "Jan";
      case 1:
        return "Feb";
      case 2:
        return "Mar";
      case 3:
        return "Apr";
      case 4:
        return "Mei";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Agu";
      case 8:
        return "Sep";
      case 9:
        return "Okt";
      case 10:
        return "Nov";
      case 11:
        return "Des";
    }
  };

  const messageDate = messageTimestamp.getDate();
  const messageMonth = getMonthString(messageTimestamp.getMonth());
  const messageYear = messageTimestamp.getFullYear();

  return (
    <div className="chat-message-date">
      <p>{`${messageDate} ${messageMonth} ${messageYear}`}</p>
    </div>
  );
};

export default ChatMessageDate;
