import { useContext } from "react";

import { ChatInfoContext } from "../../contexts/chat-info.context";
import ChatNavbar from "../chat-navbar/chat-navbar.component";
import ChatSearch from "../chat-search/chat-search.component";
import ChatList from "../chat-list/chat-list.component";

import "./chat-sidebar.styles.scss";

const ChatSidebar = () => {
  const { data } = useContext(ChatInfoContext);

  return (
    <div
      className={`chat-sidebar ${
        data.userInfo.username ? "hide-chat-sidebar" : "show-chat-sidebar"
      }`}
    >
      <ChatNavbar />
      <ChatSearch />
      <ChatList />
    </div>
  );
};

export default ChatSidebar;
