import { useContext } from "react";

import { UserContext } from "../../contexts/user.context";
import "./chat-navbar.styles.scss";

const ChatNavbar = () => {
  const { userDocument } = useContext(UserContext);

  return (
    <div className="chat-navbar">
      <img src={userDocument.profilePicture} alt="" />
      <span>{userDocument.username}</span>
    </div>
  );
};

export default ChatNavbar;
