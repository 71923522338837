import { createContext, useState } from "react";

export const UserInfoContext = createContext({
  isUserInfoOpen: false,
  setIsUserInfoOpen: () => {},
});

export const UserInfoProvider = ({ children }) => {
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const value = { isUserInfoOpen, setIsUserInfoOpen };

  return (
    <UserInfoContext.Provider value={value}>
      {children}
    </UserInfoContext.Provider>
  );
};
