import { createContext, useState, useEffect, useContext } from "react";

import { UserContext } from "./user.context";
import { getUserRatingDocument } from "../utils/firebase/firebase.utils";

export const UsersRatingContext = createContext({
  usersRating: {},
  setUsersRating: () => null,
  usersRatingUpdateFlag: false,
  setUsersRatingUpdateFlag: () => null,
});

export const UsersRatingProvider = ({ children }) => {
  const [usersRating, setUsersRating] = useState({});
  const [usersRatingUpdateFlag, setUsersRatingUpdateFlag] = useState(false);
  const { currentUser } = useContext(UserContext);
  const value = {
    usersRating,
    setUsersRating,
    usersRatingUpdateFlag,
    setUsersRatingUpdateFlag,
  };

  useEffect(() => {
    if (!currentUser) return setUsersRating({});

    const getUsersRating = async () => {
      const usersRating = await getUserRatingDocument(currentUser);
      setUsersRating(usersRating);
    };
    getUsersRating();
  }, [currentUser, usersRatingUpdateFlag]);

  return (
    <UsersRatingContext.Provider value={value}>
      {children}
    </UsersRatingContext.Provider>
  );
};
