import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { UserContext } from "../../contexts/user.context";
import {
  createUserDocumentFromAuth,
  createUserChatsDocumentFromAuth,
  createUserRatingDocumentFromAuth,
  loginWithGooglePopup,
  // loginWithGoogleRedirect,
  // getLoginWithGoogleRedirectResult,
  loginAuthUserWithEmailandPassword,
} from "../../utils/firebase/firebase.utils";
import "./login-form.styles.scss";

const defaultFormFields = {
  email: "",
  password: "",
};

const LoginForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;

  const {
    // currentUser,
    userDocument,
    userDocumentUpdateFlag,
    setUserDocumentUpdateFlag,
  } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (userDocument && Object.keys(userDocument).length > 0) {
      navigate(`/${userDocument.username}`);
    }
  }, [userDocument]);

  const loginWithGooglePopupHandler = async () => {
    const { user } = await loginWithGooglePopup();

    await createUserDocumentFromAuth(user, {
      profilePicture: "",
      gender: "default",
      dateOfBirth: "",
      city: "",
      state: "",
      description: "",
      favouriteFood: "",
      hobby: "",
    });

    setUserDocumentUpdateFlag(!userDocumentUpdateFlag);

    await createUserChatsDocumentFromAuth(user);

    await createUserRatingDocumentFromAuth(user);
  };

  // const loginWithGoogleRedirectHandler = async () => {
  //   await loginWithGoogleRedirect();
  // };
  // useEffect(() => {
  //   console.log("triggered");
  //   if (!currentUser) return;

  //   const getUserLoginWithGoogleRedirect = async () => {
  //     const result = await getLoginWithGoogleRedirectResult();
  //     const user = result.user;

  //     await createUserDocumentFromAuth(user, {
  //       profilePicture: "",
  //       gender: "default",
  //       dateOfBirth: "",
  //       city: "",
  //       state: "",
  //       description: "",
  //       favouriteFood: "",
  //       hobby: "",
  //     });

  //     setUserDocumentUpdateFlag(!userDocumentUpdateFlag);

  //     await createUserChatsDocumentFromAuth(user);

  //     await createUserRatingDocumentFromAuth(user);
  //   };
  //   getUserLoginWithGoogleRedirect();
  // }, [currentUser]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await loginAuthUserWithEmailandPassword(email, password);
      setUserDocumentUpdateFlag(!userDocumentUpdateFlag);
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password":
          alert("incorrect password for email");
          break;
        case "auth/user-not-found":
          alert("no user associated with this email");
          break;
        default:
          console.log(error);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="group">
          <input
            className="form-input"
            type="email"
            required
            onChange={handleChange}
            name="email"
            value={email}
          />
          <label className={`form-input-label ${email.length ? "slide" : ""}`}>
            Email*
          </label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="password"
            required
            onChange={handleChange}
            name="password"
            value={password}
          />
          <label
            className={`form-input-label ${password.length ? "slide" : ""}`}
          >
            Kata Sandi*
          </label>
        </div>
        <div className="buttons-container">
          <button className="form-button">Masuk</button>
          <button
            className="form-button-google"
            onClick={loginWithGooglePopupHandler}
          >
            Masuk dengan Google
          </button>
          {/* <button
            className="form-button-google"
            onClick={loginWithGoogleRedirectHandler}
          >
            Masuk dengan Google Redirect
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
