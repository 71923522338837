import { useContext, useState, useEffect } from "react";

import { UserContext } from "../../contexts/user.context";
import { UserInfoContext } from "../../contexts/user-info.context";

import { FaAngleDown } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";

import "./user-info.styles.scss";

const UserInfo = () => {
  const { userDocument } = useContext(UserContext);
  const { isUserInfoOpen, setIsUserInfoOpen } = useContext(UserInfoContext);
  const [realName, setRealName] = useState("");

  const toggleIsUserInfoOpen = () => setIsUserInfoOpen(!isUserInfoOpen);

  useEffect(() => {
    if (userDocument && Object.keys(userDocument).length > 0) {
      setRealName(userDocument.realName);
    }
  }, [userDocument]);

  return (
    <div className="user-info-container" onClick={toggleIsUserInfoOpen}>
      <div className="user-info-menu">
        <FiMenu size="1.8em" color="gray" />
      </div>
      <div className="user-info-full">
        <span className="user-info">Hi, {realName}</span>
        <FaAngleDown size="1em" color="gray" />
      </div>
    </div>
  );
};

export default UserInfo;
