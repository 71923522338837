import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ParamsContext } from "../../contexts/params.context";
import ProfileList from "../../components/profile-list/profile-list.component";

const Discovery = ({ users }) => {
  const { username } = useParams();
  const { setParams } = useContext(ParamsContext);

  useEffect(() => {
    setParams(username);

    return () => {
      setParams("");
    };
  }, []);

  return <ProfileList users={users} />;
};

export default Discovery;
