import { createContext, useState, useEffect } from "react";

import {
  onAuthStateChangedListener,
  getUserDocument,
} from "../utils/firebase/firebase.utils";

// Actual value we want to access
export const UserContext = createContext({
  currentUser: null,
  setCurrentUser: () => null,
  userDocument: {},
  setUserDocument: () => null,
  userDocumentUpdateFlag: false,
  setUserDocumentUpdateFlag: () => null,
});

// The function itself
export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userDocument, setUserDocument] = useState({});
  const [userDocumentUpdateFlag, setUserDocumentUpdateFlag] = useState(false);
  const value = {
    currentUser,
    setCurrentUser,
    userDocument,
    setUserDocument,
    userDocumentUpdateFlag,
    setUserDocumentUpdateFlag,
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener((user) => {
      setCurrentUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      const getUserDoc = async () => {
        const userDoc = await getUserDocument(currentUser);
        setUserDocument(userDoc);
      };
      getUserDoc();
    } else {
      setUserDocument({});
    }
  }, [currentUser, userDocumentUpdateFlag]);

  // console.log(currentUser);
  // console.log(userDocument);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
