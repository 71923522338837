import { useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { BiHome } from "react-icons/bi";

import { UserContext } from "../../contexts/user.context";
import "./logo.styles.scss";

const Logo = () => {
  const { userDocument } = useContext(UserContext);

  return (
    <div className="logo-container">
      {userDocument && Object.keys(userDocument).length > 0 ? (
        <Fragment>
          <Link to={`/${userDocument.username}`}>
            <BiHome className="logo-icon" size="2em" color="gray" />
          </Link>
          <Link className="logo-full" to={`/${userDocument.username}`}>
            Makan Yu
          </Link>
        </Fragment>
      ) : (
        <Link className="logo-default" to="/">
          Makan Yu
        </Link>
      )}
    </div>
  );
};

export default Logo;
