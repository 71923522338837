import { useContext, useRef, useEffect } from "react";

import { UserContext } from "../../contexts/user.context";
import { ChatInfoContext } from "../../contexts/chat-info.context";

import "./chat-message.styles.scss";

const ChatMessage = ({ message }) => {
  const { userDocument } = useContext(UserContext);
  const { data } = useContext(ChatInfoContext);

  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  const { date } = message;
  const messageHours = date.toDate().getHours();
  const messageMinutes =
    date.toDate().getMinutes() < 10
      ? `0${date.toDate().getMinutes()}`
      : date.toDate().getMinutes();

  return (
    <div
      ref={ref}
      className={`chat-message ${
        message.senderUid === userDocument.uid && "owner"
      }`}
    >
      <div className="chat-message-info">
        <img
          src={
            message.senderUid === userDocument.uid
              ? userDocument.profilePicture
              : data.userInfo.profilePicture
          }
          alt=""
        />
        <span>{`${messageHours}:${messageMinutes}`}</span>
      </div>
      <div className="chat-message-content">
        {message.text && <p>{message.text}</p>}
        {message.imageUrl && <img src={message.imageUrl} alt="" />}
      </div>
    </div>
  );
};

export default ChatMessage;
