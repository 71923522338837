import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  createAuthUserWithEmailandPassword,
  createUserDocumentFromAuth,
  createUserChatsDocumentFromAuth,
  createUserRatingDocumentFromAuth,
  verifyEmail,
  uploadFile,
  logoutAuthUser,
} from "../../utils/firebase/firebase.utils";
import { UserContext } from "../../contexts/user.context";
import { UsersContext } from "../../contexts/users.context";
import "./register-form.styles.scss";

const defaultFormFields = {
  email: "",
  password: "",
  confirmPassword: "",
  username: "",
  realName: "",
  imageUrl: "",
  gender: "default",
  dateOfBirth: "",
  city: "",
  state: "",
  description: "",
  favouriteFood: "",
  hobby: "",
};

const schema = yup.object().shape({
  imageUrl: yup
    .mixed()
    .test("fileSize", "Ukuran Foto Profil maksimal 3 MB", (value) => {
      if (value.length == 0) {
        return true;
      } else {
        return value && value[0].size <= 3000000;
      }
    }),
});

const RegisterForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const {
    email,
    password,
    confirmPassword,
    username,
    realName,
    imageUrl,
    gender,
    dateOfBirth,
    city,
    state,
    description,
    favouriteFood,
    hobby,
  } = formFields;

  const [profilePictureImage, setProfilePictureImage] = useState(null);

  const { userDocumentUpdateFlag, setUserDocumentUpdateFlag } =
    useContext(UserContext);
  const { allUsernames } = useContext(UsersContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const navigate = useNavigate();

  const submitHandler = async (data, event) => {
    event.preventDefault();

    if (allUsernames[username.toLocaleLowerCase()]) {
      alert("Username telah terpakai, silahkan masukkan Username lain");
      return;
    }

    if (password !== confirmPassword) {
      alert("Kata sandi tidak sama");
      return;
    }

    try {
      // console.log("try block");

      const { user } = await createAuthUserWithEmailandPassword(
        email,
        password
      );

      // console.log("done creating user");

      const profilePicture = await uploadFile(
        "profile-picture",
        profilePictureImage,
        user.uid
      );

      // console.log("done getting profile picture url");

      await createUserDocumentFromAuth(user, {
        username,
        realName,
        profilePicture,
        gender,
        dateOfBirth,
        city,
        state,
        description,
        favouriteFood,
        hobby,
      });

      // console.log("done creating user document");

      setUserDocumentUpdateFlag(!userDocumentUpdateFlag);

      await createUserChatsDocumentFromAuth(user);

      // console.log("done creating userChat document");

      await createUserRatingDocumentFromAuth(user);

      // console.log("done creating userRating document");

      await verifyEmail();

      alert(
        "email verifikasi telah dikirim, lakukan verifikasi email sebelum masuk"
      );

      logoutAuthUser();
      navigate("/login");
    } catch (error) {
      if (error.code == "auth/email-already-in-use") {
        alert("email sudah terdaftar");
      } else {
        alert("terjadi kesalahan", error);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleImageUrlChange = (event) => {
    const { name, value, files } = event.target;
    setFormFields({ ...formFields, [name]: value });
    setProfilePictureImage(files[0]);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submitHandler)} className="register-form">
        <div className="group">
          <input
            className="form-input"
            type="email"
            required
            onChange={handleChange}
            name="email"
            value={email}
          />
          <label className={`form-input-label ${email.length ? "slide" : ""}`}>
            Email*
          </label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="password"
            required
            onChange={handleChange}
            name="password"
            value={password}
          />
          <label
            className={`form-input-label ${password.length ? "slide" : ""}`}
          >
            Kata Sandi*
          </label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="password"
            required
            onChange={handleChange}
            name="confirmPassword"
            value={confirmPassword}
          />
          <label
            className={`form-input-label ${
              confirmPassword.length ? "slide" : ""
            }`}
          >
            Ulangi Kata Sandi*
          </label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="text"
            required
            onChange={handleChange}
            name="username"
            value={username}
            maxLength="20"
          />
          <label
            className={`form-input-label ${username.length ? "slide" : ""}`}
          >
            Username*
          </label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="text"
            onChange={handleChange}
            name="realName"
            value={realName}
          />
          <label
            className={`form-input-label ${realName.length ? "slide" : ""}`}
          >
            Nama Asli
          </label>
        </div>
        <div className="group">
          <input
            {...register("imageUrl")}
            // className={`form-input ${
            //   imageUrl.length ? "" : "form-file"
            // }`}
            className="form-input form-file"
            type="file"
            accept=".jpg, .jpeg"
            onChange={handleImageUrlChange}
            value={imageUrl}
          />
          <label
            className={`form-input-label ${imageUrl.length ? "slide" : ""}`}
          >
            Foto Profil
          </label>
          <label
            className={`form-file-label ${imageUrl.length ? "exist" : "empty"}`}
          >
            {imageUrl.length ? imageUrl : "Nama File"}
          </label>
          {errors.imageUrl && <p>{errors.imageUrl.message}</p>}
        </div>
        <div className="group">
          <select
            className="form-input"
            onChange={handleChange}
            name="gender"
            value={gender}
          >
            <option value="default"></option>
            <option value="laki-laki">Laki-laki</option>
            <option value="perempuan">Perempuan</option>
          </select>
          <label
            className={`form-input-label ${
              gender !== "default" ? "slide" : ""
            }`}
          >
            Jenis Kelamin
          </label>
        </div>
        <div className="group">
          <input
            className={`form-input form-date ${
              dateOfBirth.length ? "exist" : "empty"
            }`}
            type="date"
            // type="text"
            // onFocus={(event) => (event.target.type = "date")}
            // onBlur={(event) => (event.target.type = "text")}
            onChange={handleChange}
            name="dateOfBirth"
            value={dateOfBirth}
          />
          <label
            className={`form-input-label ${dateOfBirth.length ? "slide" : ""}`}
          >
            Tanggal Lahir
          </label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="text"
            onChange={handleChange}
            name="city"
            value={city}
          />
          <label className={`form-input-label ${city.length ? "slide" : ""}`}>
            Kota
          </label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="text"
            onChange={handleChange}
            name="state"
            value={state}
          />
          <label className={`form-input-label ${state.length ? "slide" : ""}`}>
            Provinsi
          </label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="text"
            onChange={handleChange}
            name="description"
            value={description}
            maxLength="80"
          />
          <label
            className={`form-input-label ${description.length ? "slide" : ""}`}
          >
            Deskripsi Diri
          </label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="text"
            onChange={handleChange}
            name="favouriteFood"
            value={favouriteFood}
            maxLength="20"
          />
          <label
            className={`form-input-label ${
              favouriteFood.length ? "slide" : ""
            }`}
          >
            Makanan Favorit
          </label>
        </div>
        <div className="group">
          <input
            className="form-input"
            type="text"
            onChange={handleChange}
            name="hobby"
            value={hobby}
            maxLength="20"
          />
          <label className={`form-input-label ${hobby.length ? "slide" : ""}`}>
            Hobi
          </label>
        </div>
        <button className="form-button">Daftar</button>
      </form>
    </div>
  );
};

export default RegisterForm;
