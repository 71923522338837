// import { Component } from 'react';
import { useState, useEffect, useRef, useContext, Children } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { UsersContext } from "./contexts/users.context";
import { UserContext } from "./contexts/user.context";

import Header from "./routes/header/header.component";
import Home from "./routes/home/home.component";
import Discovery from "./routes/discovery/discovery.component";
import Login from "./routes/login/login.component";
import Register from "./routes/register/register.component";
import ForgetPassword from "./routes/forget-password/forget-password.component";
import Profile from "./routes/profile/profile.component";
import ChangePassword from "./routes/change-password/change-password.component";
import Chat from "./routes/chat/chat.component";

import "./App.scss";

//Functional component with hooks
const App = () => {
  const { users } = useContext(UsersContext);
  const { currentUser, userDocument } = useContext(UserContext);
  // const [users, setUsers] = useState([]); // useState returns an array of 2 values [initialValue, setValue]
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [searchField, setSearchField] = useState("");
  const contextRef = useRef();

  // useEffect(() => {
  //   fetch("https://jsonplaceholder.typicode.com/users")
  //     .then((response) => response.json())
  //     .then((users) => setUsers(users));
  // }, []);

  //useEffect take two parameters : callback function and array of dependencies (whose change will trigger component to run again)
  useEffect(() => {
    const newFilteredUsers = users.filter((user) => {
      return (
        user.username.toLocaleLowerCase().includes(searchField) ||
        user.city.toLocaleLowerCase().includes(searchField) ||
        user.state.toLocaleLowerCase().includes(searchField) ||
        user.favouriteFood.toLocaleLowerCase().includes(searchField)
      );
    });

    setFilteredUsers(newFilteredUsers);
  }, [users, searchField]);

  const onSearchEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const searchFieldString = event.target.value.toLocaleLowerCase();
      setSearchField(searchFieldString);
    }
  };

  const onClearSearch = (event) => {
    if (!event.target.value) {
      setSearchField("");
    }
  };

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/login" replace />;
    }

    return children;
  };

  const LoggedoutRoute = ({ children }) => {
    if (userDocument && Object.keys(userDocument).length > 0) {
      return <Navigate to={`/${userDocument.username}`} replace />;
    }

    return children;
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Header
            onSearchEnter={onSearchEnter}
            onClearSearch={onClearSearch}
            contextRef={contextRef}
          />
        }
      >
        <Route
          index
          element={
            <LoggedoutRoute>
              <Home />
            </LoggedoutRoute>
          }
        />
        <Route
          path="/:username"
          element={
            <ProtectedRoute>
              <Discovery users={filteredUsers} />
            </ProtectedRoute>
          }
        />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route
          path="profile/:username"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="change-password/:username"
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="chat/:username"
          element={
            <ProtectedRoute>
              <Chat />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
};

// Class component
// class App extends Component {
//   constructor() {
//     super();

//     this.state = {
//       users: [],
//       searchField: ''
//     }
//   }

//   componentDidMount() {
//     fetch('https://jsonplaceholder.typicode.com/users')
//       .then((response) => response.json())
//       .then((users) =>
//         this.setState(() => { return { users } })
//     );
//   }

//   onSearchEnter = (event) => {
//     if (event.key === 'Enter') {
//       const searchField = event.target.value.toLocaleLowerCase();
//       this.setState(() => { return { searchField } });
//     }
//   }

//   onClearSearch = (event) => {
//     if (!event.target.value) {
//       this.setState(() => { return { searchField: '' } });
//     }
//   }

//   render() {
//     const { users, searchField } = this.state;
//     const { onSearchEnter, onClearSearch } = this;

//     const filteredUsers = users.filter((user) => {
//       return user.name.toLocaleLowerCase().includes(searchField);
//     });

//     return (
//       <div className="App">
//         <h1 className='app-title'>Teman Makan</h1>
//         <SearchBox
//           className='profile-search-box'
//           placeholder='Username / Area / Jenis Makanan'
//           onEnter={onSearchEnter}
//           onClear={onClearSearch}
//         />
//         <ProfileList users={filteredUsers} />
//       </div>
//     );
//   }
// }

export default App;
