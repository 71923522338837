import { Link } from "react-router-dom";
import "./home.styles.scss";

const Home = () => {
  return (
    <div className="home-container">
      <h1 className="home-title">3 langkah, teman nambah</h1>
      <div className="usp">
        <Link to="/login">
          <button type="button">Masuk</button>
        </Link>
        <h2 className="usp-title">atau</h2>
        <Link to="/register">
          <button type="button">Daftar</button>
        </Link>
      </div>
      <div className="usp">
        <h2 className="usp-title">
          Temukan teman baru <br /> dan
        </h2>
        <button type="button">Ajak Makan</button>
      </div>
      <div className="usp">
        <h2 className="usp-title">
          Buat janji dan ngobrol <br /> dengan fitur
        </h2>
        <button type="button">Chat</button>
      </div>
    </div>
  );
};

export default Home;
