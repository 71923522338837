import { createContext, useContext, useReducer } from "react";

import { UserContext } from "./user.context";

export const ChatInfoContext = createContext();

export const ChatInfoProvider = ({ children }) => {
  const { currentUser } = useContext(UserContext);

  const INITIAL_STATE = {
    userInfo: {},
    combinedId: "null",
  };

  const chatInfoReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_USER":
        return {
          userInfo: action.payload,
          combinedId:
            currentUser.uid > action.payload.uid
              ? currentUser.uid + action.payload.uid
              : action.payload.uid + currentUser.uid,
        };
      case "RESET":
        return INITIAL_STATE;
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(chatInfoReducer, INITIAL_STATE);

  return (
    <ChatInfoContext.Provider value={{ data: state, dispatch }}>
      {children}
    </ChatInfoContext.Provider>
  );
};
