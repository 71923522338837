import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import App from "./App";
import { MediaQueryProvider } from "./contexts/media-query.context";
import { ParamsProvider } from "./contexts/params.context";
import { UserProvider } from "./contexts/user.context";
import { UsersProvider } from "./contexts/users.context";
import { ChatInfoProvider } from "./contexts/chat-info.context";
import { UserInfoProvider } from "./contexts/user-info.context";
import { UsersRatingProvider } from "./contexts/user-rating.context";

import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MediaQueryProvider>
        <ParamsProvider>
          <UserProvider>
            <UsersProvider>
              <ChatInfoProvider>
                <UsersRatingProvider>
                  <UserInfoProvider>
                    <App />
                  </UserInfoProvider>
                </UsersRatingProvider>
              </ChatInfoProvider>
            </UsersProvider>
          </UserProvider>
        </ParamsProvider>
      </MediaQueryProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
