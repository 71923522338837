// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  signInWithRedirect,
  getRedirectResult,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updatePassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  query,
  // getDocs,
  serverTimestamp,
  onSnapshot,
  arrayUnion,
  Timestamp,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCrTt6rXyn0Mfc9Gj2CUlIU2x87_rogenc",
  authDomain: "my-1-84a34.firebaseapp.com",
  projectId: "my-1-84a34",
  storageBucket: "my-1-84a34.appspot.com",
  messagingSenderId: "793959288821",
  appId: "1:793959288821:web:590b7194e91c054b339abc",
  measurementId: "G-VH2KP3Y8NB",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

// Providers
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: "select_account",
});

// Auth singleton
export const auth = getAuth();

// Database
export const db = getFirestore();

// Creating authUser from register form
export const createAuthUserWithEmailandPassword = async (email, password) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

// Send email verification email after register
export const verifyEmail = async () => sendEmailVerification(auth.currentUser);

// Sign in methods
export const loginWithGooglePopup = () => signInWithPopup(auth, googleProvider);

export const loginWithGoogleRedirect = () =>
  signInWithRedirect(auth, googleProvider);
export const getLoginWithGoogleRedirectResult = () => getRedirectResult(auth);

// Login authUser from login form
export const loginAuthUserWithEmailandPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

// Logout authUser
export const logoutAuthUser = () => signOut(auth);

// Change password
export const changePassword = async (newPassword) =>
  await updatePassword(auth.currentUser, newPassword);

// Reset password, send reset password email
export const resetPassword = async (email) =>
  await sendPasswordResetEmail(auth, email);

// auth listener
export const onAuthStateChangedListener = (callback) => {
  return onAuthStateChanged(auth, callback);
};

export const createUserDocumentFromAuth = async (
  userAuth,
  additionalInformation
) => {
  if (!userAuth) return;

  const userDocRef = doc(db, "users", userAuth.uid); // Creating user document reference, take 3 arguments, the database, collection name and document unique id.
  const userSnapshot = await getDoc(userDocRef); // Taking snapshot of the content of the document.

  //If user data does not exist
  //create or set the document with the data from userAuth in my collection.
  if (!userSnapshot.exists()) {
    const { email, displayName, uid } = userAuth;
    const joinDate = new Date();

    try {
      await setDoc(userDocRef, {
        email: email,
        username: email,
        realName: displayName,
        joinDate: joinDate,
        uid: uid,
        ratingValueTotal: 0,
        ratingCount: 0,
        ...additionalInformation,
      });
    } catch (error) {
      console.log("error creating the user", error.message);
    }
  }

  //If user data exists
  //return userDocRef.
  return userDocRef;
};

export const updateUserDocument = async (userAuth, newInformation) => {
  const userDocRef = doc(db, "users", userAuth.uid);

  try {
    await updateDoc(userDocRef, {
      ...newInformation,
    });
  } catch (error) {
    console.log("error updating User profile data", error.message);
  }
};

export const getUserDocument = async (userAuth) => {
  const userDocRef = doc(db, "users", userAuth.uid);
  const q = query(userDocRef);

  const querySnapshot = await getDoc(q);
  const userDocument = querySnapshot.data();
  return userDocument;
};

// export const getUsers = async () => {
//   const usersCollectionRef = collection(db, "users");
//   const q = query(usersCollectionRef);

//   const querySnapshot = await getDocs(q);
//   const usersMap = querySnapshot.docs.map((docSnapshot) => docSnapshot.data());

//   return usersMap;
// };

export const onUsersChangedListener = (callback) => {
  const usersCollectionRef = collection(db, "users");

  return onSnapshot(usersCollectionRef, callback);
};

export const createUserChatsDocumentFromAuth = async (userAuth) => {
  if (!userAuth) return;

  const userChatsDocRef = doc(db, "userChats", userAuth.uid);
  const userChatsSnapshot = await getDoc(userChatsDocRef);

  if (!userChatsSnapshot.exists()) {
    setDoc(userChatsDocRef, {});
  }

  return userChatsDocRef;
};

export const updateUserChatsDocument = async (
  userAuth,
  combinedId,
  updateTimeStamp,
  newUserChats
) => {
  const userChatsDocRef = doc(db, "userChats", userAuth.uid);

  if (updateTimeStamp) {
    await updateDoc(userChatsDocRef, {
      ...newUserChats,
      [combinedId + ".date"]: serverTimestamp(),
    });
  } else {
    await updateDoc(userChatsDocRef, {
      ...newUserChats,
    });
  }
};

export const onUserChatsChangedListener = (userAuth, callback) => {
  const userChatsDocRef = doc(db, "userChats", userAuth.uid);

  return onSnapshot(userChatsDocRef, callback);
};

export const createChatsDocument = async (combinedId) => {
  const chatsDocRef = doc(db, "chats", combinedId);
  const chatsSnapshot = await getDoc(chatsDocRef);

  if (!chatsSnapshot.exists()) {
    await setDoc(chatsDocRef, { messages: [] });
  }

  return chatsDocRef;
};

export const updateChatsDocument = async (
  combinedId,
  messageUid,
  senderUid,
  text,
  imageUrl
) => {
  const chatsDocRef = doc(db, "chats", combinedId);

  await updateDoc(chatsDocRef, {
    messages: arrayUnion({
      uid: messageUid,
      senderUid,
      text,
      imageUrl: imageUrl ? imageUrl : "",
      date: Timestamp.now(),
    }),
  });
};

export const onChatsChangedListener = (combinedId, callback) => {
  const chatsDocRef = doc(db, "chats", combinedId);

  return onSnapshot(chatsDocRef, callback);
};

export const createUserRatingDocumentFromAuth = async (userAuth) => {
  if (!userAuth) return;

  const userRatingDocRef = doc(db, "userRating", userAuth.uid);
  const userRatingSnapshot = await getDoc(userRatingDocRef);

  if (!userRatingSnapshot.exists()) {
    setDoc(userRatingDocRef, {});
  }

  return userRatingDocRef;
};

export const updateUserRatingDocument = async (userAuth, user, ratingValue) => {
  const userRatingDocRef = doc(db, "userRating", userAuth.uid);

  await updateDoc(userRatingDocRef, {
    [user.uid + ".date"]: serverTimestamp(),
    [user.uid + ".ratingValue"]: ratingValue,
  });

  const userDoc = await getUserDocument(user);

  await updateUserDocument(user, {
    ratingValueTotal: userDoc.ratingValueTotal + ratingValue,
    ratingCount: userDoc.ratingCount + 1,
  });
};

export const getUserRatingDocument = async (userAuth) => {
  const userRatingDocRef = doc(db, "userRating", userAuth.uid);
  const q = query(userRatingDocRef);

  const querySnapshot = await getDoc(q);
  const userRatingDocument = querySnapshot.data();

  return userRatingDocument;
};

// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(firebaseApp);

// Uploading file in Cloud Storage and get back the URL
export const uploadFile = async (path, file, uid) => {
  const fileRef = ref(storage, `${path}/${uid}`);
  await uploadBytes(fileRef, file);
  return await getDownloadURL(fileRef);
};
