import { useState, useContext, useEffect, Fragment } from "react";

import { ChatInfoContext } from "../../contexts/chat-info.context";
import { onChatsChangedListener } from "../../utils/firebase/firebase.utils";
import ChatMessage from "../chat-message/chat-message.component";
import ChatMessageDate from "../chat-message-date/chat-message-date.component";

import "./chat-messages.styles.scss";

const ChatMessages = () => {
  const [messages, setMessages] = useState([]);
  const { data } = useContext(ChatInfoContext);

  useEffect(() => {
    const unsubscribe = onChatsChangedListener(data.combinedId, (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });

    return () => {
      unsubscribe();
    };
  }, [data]);

  return (
    <div className="chat-messages">
      {messages.map((message, i, array) => {
        const prevMessage = array[i - 1];

        return (
          <Fragment key={message.uid}>
            {(message.date.toDate().getFullYear() !==
              prevMessage?.date.toDate().getFullYear() ||
              message.date.toDate().getMonth() !==
                prevMessage?.date.toDate().getMonth() ||
              message.date.toDate().getDate() !==
                prevMessage?.date.toDate().getDate()) && (
              <ChatMessageDate messageTimestamp={message.date.toDate()} />
            )}
            <ChatMessage message={message} />
          </Fragment>
        );
      })}
    </div>
  );
};

export default ChatMessages;
