import ForgetPasswordForm from "../../components/forget-password-form/forget-password-form.component";
import "./forget-password.styles.scss";

const ForgetPassword = () => {
  return (
    <div>
      <h2 className="forget-password-page-title">Masukkan email</h2>
      <ForgetPasswordForm />
    </div>
  );
};

export default ForgetPassword;
